.logo-container {
  z-index: 0;
  width: 400px;
  height: 609px;
  position: absolute;
  top: 30%;
  left: 65%;

  svg {
    width: 100%;
    animation: fadeIn 3s linear both;
    animation-delay: 1s;
    height: auto;
    bottom: 0;

    @media screen and (max-width: 768px) {
      display: none;
    }

    @media (min-width: 790px) and (max-width: 940px) {
      margin-top: -100px;
    }
  }
}
