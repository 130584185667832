html {
  font-size: 100%;
}

body {
  margin: 0;
  font: 300 13px/1.4 'Helvetica Neue', 'sans-serif';
  color: #444;
  background-image: url('assets/images/starry-night-sky.jpg');
  overflow: hidden;
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
