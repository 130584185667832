.page {
  width: 100%;
  height: 100%;
  position: absolute;
}

.tags {
  color: #ffd700;
  opacity: 0.6;
  position: absolute;
  bottom: 0;
  left: 30px;
  font-size: 18px;
  font-family: 'La Belle Aurore', sans-serif;
  transform-style: preserve-3d;
  animation: fadeIn 1s forwards;

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.top-tags {
  position: fixed;
  top: 100px;
  left: 125px;
}

.bottom-tags {
  position: fixed;
  bottom: 100px;
  left: 125px;
}

.bottom-tag-html {
  margin-left: -25px;
}

.container {
  width: 100%;
  height: 90%;
  will-change: contents;
  min-height: 566px;
  position: absolute;
  opacity: 0;
  top: 5%;
  margin: 0 auto;
  z-index: 1;
  transform-style: preserve-3d;
  animation: fadeIn 1s forwards;
  animation-delay: 1s;
}

.about-page,
.contact-page {
  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 55%;
    vertical-align: middle;
    display: table-cell;
    max-height: 90%;

    @media screen and (max-width: 768px) {
      left: -15%;
      width: 70%;
    }

    h1 {
      font-size: 53px;
      font-family: 'Coolvetica';
      font-weight: 400;
      color: #ffd700;
      margin-top: 0;
      position: relative;
      margin-bottom: 40px;
      left: 100px;

      @media screen and (max-width: 768px) {
        font-size: 40px;
        white-space: nowrap;
      }

      &::before {
        content: '<h1>';
        font-family: 'La Belle Aurore';
        font-size: 18px;
        position: absolute;
        margin-top: -10px;
        left: -10px;
        opacity: 0.6;
        line-height: 18px;
      }

      &::after {
        content: '</h1>';
        font-family: 'La Belle Aurore';
        font-size: 18px;
        line-height: 18px;
        position: absolute;
        margin-top: -10px;
        left: -10px;
        bottom: -20px;
        opacity: 0.6;
      }
    }

    p {
      font-style: 18px;
      color: #fff;
      font-family: sans-serif;
      font-weight: 300;
      min-width: fit-content;
      animation: pulse 1s;
      position: relative;
      left: 100px;
      line-height: 20px;
      margin-bottom: 20px;
      &:nth-of-type(1) {
        animation-delay: 1.1s;
      }
      &:nth-of-type(2) {
        animation-delay: 1.2s;
      }
      &:nth-of-type(3) {
        animation-delay: 1.3s;
      }
    }
  }

  .text-animate-hover {
    &:hover {
      color: #fff;
    }
  }
}
