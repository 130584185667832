.home-page {
  .text-zone {
    position: absolute;
    left: 15%;
    top: 50%;
    transform: translateY(-50%);
    width: 50%;
    max-height: 90%;

    @media screen and (max-width: 768px) {
      width: 100%;
      left: 7px;
      top: 20%;
      transform: none;
      padding: 20px;
    }
  }

  h1 {
    color: #fff;
    font-size: 53px;
    margin: 0;
    font-family: 'Coolvetica';
    font-weight: 400;

    @media screen and (max-width: 768px) {
      font-size: 25px;
      white-space: nowrap;
    }

    @media (min-width: 790px) and (max-width: 940px) {
      white-space: nowrap;
    }

    &::before {
      content: '<h1>';
      font-family: 'La Belle Aurore';
      color: #ffd700;
      font-size: 18px;
      position: absolute;
      margin-top: -40px;
      left: 10px;
      opacity: 0.6;

      @media screen and (max-width: 768px) {
        margin-top: -20px;
      }
    }

    &::after {
      content: '</h1>';
      font-family: 'La Belle Aurore';
      color: #ffd700;
      font-size: 18px;
      position: absolute;
      margin-top: 18px;
      margin-left: 20px;
      animation: fadeIn 1s 1.7s backwards;
      opacity: 0.6;
    }
    img {
      width: 45px;
      margin-left: 20px;
      opacity: 0;
      height: auto;
      animation: rotateIn 1s linear both;
      animation-delay: 1s;
    }
  }
}

h2 {
  color: #8d8d8d;
  margin-top: 20px;
  font-weight: 11px;
  font-family: sans-serif;
  letter-spacing: 3px;
  animation: fadeIn 1s 1.4s backwards;
  word-break: break-word;
  word-wrap: break-word;

  @media screen and (max-width: 768px) {
    font-size: 15px;
  }
}

.flat-button {
  color: #ffd700;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 4px;
  font-family: sans-serif;
  text-decoration: none;
  padding: 10px 18px;
  border: 1px solid #ffd700;
  margin-top: 25px;
  float: left;
  animation: fadeIn 1s 1.8s backwards;
  white-space: nowrap;

  &:hover {
    background: #ffd700;
    color: #333;
  }
}

.logo-container {
  @media screen and (max-width: 768px) {
    display: none;
  }
}
